import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        token: null,
        userInfo: {},
        isSignedIn: false,
        isLoginCompleted: false,
        permissions: [],
        roleList: [],

    },
    reducers: {
        setToken: (state, action) => {
            state.token = action.payload
            state.isSignedIn = true
            state.isLoginCompleted = true

        },
        setIsLoginCompleted: (state, action) => {
            state.isLoginCompleted = action.payload
        },
        clearToken: (state) => {
            state.token = null
            state.isSignedIn = false
            state.userInfo = {}
            state.isSignedIn = false
        },
        setUserInfo: (state, action) => {
            state.userInfo = action.payload
        },
        clearUserInfo: (state) => {
            state.userInfo = {}
        },
        setPermissions: (state, action) => {
            state.permissions = action.payload
        },
        setRoleList: (state, action) => {
            state.roleList = action.payload
        },

    },
})

// Action creators are generated for each case reducer function
export const { setToken, setIsLoginCompleted, clearToken, setUserInfo, clearUserInfo, setPermissions, setRoleList } = userSlice.actions

export default userSlice.reducer