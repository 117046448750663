import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { setToken, setUserInfo, clearToken, clearUserInfo } from '../../redux/userSlice'

const RouterAuth = props => {

    const { title, permissionCode, children } = props;

    const { userInfo, permissions, roleList, isSignedIn, isLoginCompleted } = useSelector((state) => state.user)

    // console.log('isSignedIn', isSignedIn);
    // console.log('isLoginCompleted', isLoginCompleted);
    // console.log('userInfo', userInfo);
    // console.log('permissions', permissions);
    // console.log('roleList', roleList);

    const navigate = useNavigate();

    useEffect(() => {
        document.title = title;

        if (isLoginCompleted && !isSignedIn) {
            navigate("/login", { replace: true });
        }
        else if (isLoginCompleted && isSignedIn && permissions.length && roleList.length) {
            const { roleId } = userInfo;

            const roleMatch = roleList.find(role => {
                return role.roleId == roleId
            })

            if ((roleMatch && roleMatch.actionCode.includes('All') || roleMatch.actionCode.includes(permissionCode)) || permissionCode == "dashboard") {

            } else {
                navigate("/dashboard", { replace: true });
            }
        } else {

        }

    }, [isLoginCompleted, isSignedIn, userInfo, permissions, roleList, permissionCode, title]);


    return children

}

export default RouterAuth;