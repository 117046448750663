
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setToken, setUserInfo, clearToken, clearUserInfo } from '../../redux/userSlice'

export const useHomeUser = props => {

    const navigate = useNavigate();
    const dispatch = useDispatch()

    const loginOut = useCallback(() => {
        localStorage.removeItem("ADMIN_LOGIN_TOKEN");
        dispatch(clearToken())
        dispatch(clearUserInfo())
        navigate('/login', { replace: true })
    }, [navigate]);

    return {
        loginOut
    };

}