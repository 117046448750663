import React from 'react';
import defaultClasses from './buttonNode.module.css';
import { Button } from 'antd';
import {useStyle} from '../../../utils/classify';

const ButtonNode = props=> {
    const {children, type, ...buttonProps} = props;

    const classes = useStyle(defaultClasses, props.classes);
    
    return (
        <Button
            type={type}
            {...buttonProps}
            className={`${classes.root} ${classes.btn_root}`}
        >
            {children}
        </Button>
    )
}

export default ButtonNode;