import axios from 'axios';
import { message } from 'antd';

//let rootUrl = process.env.REACT_APP_BASE_URL;
let rootUrl = '/api/admin/v1/';
let showMsg = false;

function getToken() {
    return localStorage.getItem('ADMIN_LOGIN_TOKEN');
}

axios.interceptors.request.use(
    config => {
        const token = getToken()
        if (config.url.indexOf('/login') > -1) {
            config.headers.Authorization = ''
        } else {
            config.headers.Authorization = `Bearer ${token ? token : ''}`
        }
        config.headers['Content-Type'] = "application/json";
        const { url } = config
        if (config.method === 'get') {
            //  给data赋值以绕过if判断
            config.data = true
        }
        else if (config.method === 'delete') {
            config.data = {
                data: true
            }
        }
        //const whiteList = url.includes("/customer");
        const whiteList = true;
        if (/^\/api\//.test(url) && !token && !window.location.href.indexOf('/login') > -1 && !whiteList) {
            window.location.hash = "/login"// 跳转到登录页
        }
        return config
    },
    error => {
        // Do something with request error
        console.log(error) // for debug
        Promise.reject(error)
    }
)

axios.interceptors.response.use(response => {
    if (response.data.code === 401) {
        if (!showMsg) {
            showMsg = true;
            message.error(response.data.msg);
            setTimeout(() => {
                showMsg = false;
            }, 3000);
        }
        window.location.hash = "/login";
    }
    return response;
}, (error) => {
    if (!showMsg) {
        showMsg = true;
        message.error(error.response.data.message);
        setTimeout(() => {
            showMsg = false;
        }, 3000);
    }
    if (error.response.status === 401) {
        window.location.hash = "/login";
    }
    return Promise.reject(error);
}
)

//请求封装
export function get(url, param) {
    //setHeaders(url);
    return new Promise((resolve, reject) => {
        axios.get(rootUrl + url, { params: param }).then(response => {
            resolve(response.data)
        }, err => {
            reject(err)
        }).catch((error) => {
            reject(error)
        })
    })
}

export function post(url, params) {
    //setHeaders(url);
    return new Promise((resolve, reject) => {
        axios.post(rootUrl + url, params).then(response => {
            resolve(response.data);
        }, err => {
            reject(err);
        }).catch((error) => {
            reject(error)
        })
    })
}

export function put(url, params) {
    //setHeaders(url);
    return new Promise((resolve, reject) => {
        axios.put(rootUrl + url, params).then(response => {
            resolve(response.data);
        }, err => {
            reject(err);
        }).catch((error) => {
            reject(error)
        })
    })
}

export function deleted(url) {
    //setHeaders(url);
    return new Promise((resolve, reject) => {
        axios.delete(rootUrl + url).then(response => {
            resolve(response.data);
        }, err => {
            reject(err);
        }).catch((error) => {
            reject(error)
        })
    })
}