import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import classes from './homeUser.module.css';
import { Button, Dropdown } from 'antd';
// import {UserOutlined} from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { useHomeUser } from './useHomeUser';

const HomeUser = props => {

    const navigate = useNavigate();
    const talons = useHomeUser();

    const {loginOut} = talons;

    const { userInfo, isSignedIn } = useSelector((state) => state.user)

    const items = [
        {
            key: '1',
            label: (
                <Button onClick={() => { loginOut() }}>
                    <FormattedMessage id="homeUser.loginout" defaultMessage={"Sign Out"} />
                </Button>
            ),
        }
    ]

    return (
        <div className={classes.root}>
            <Dropdown
                menu={{
                    items,
                }}
                placement="bottom"
                arrow
            >
                <div className={classes.user_root}>
                    <div className={classes.iconBox}>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g opacity="0.9">
                                <path d="M10 11.25C11.9192 11.25 13.475 9.69419 13.475 7.775C13.475 5.85581 11.9192 4.3 10 4.3C8.08081 4.3 6.525 5.85581 6.525 7.775C6.525 9.69419 8.08081 11.25 10 11.25ZM10 10C8.77117 10 7.775 9.00383 7.775 7.775C7.775 6.54617 8.77117 5.55 10 5.55C11.2288 5.55 12.225 6.54617 12.225 7.775C12.225 9.00383 11.2288 10 10 10Z" fill="white" fillOpacity="0.9" />
                                <path d="M10 18.75C14.8325 18.75 18.75 14.8325 18.75 10C18.75 5.16751 14.8325 1.25 10 1.25C5.16751 1.25 1.25 5.16751 1.25 10C1.25 14.8325 5.16751 18.75 10 18.75ZM16.2443 14.1557C14.3435 13.0956 12.2319 12.5 9.99997 12.5C7.76802 12.5 5.65649 13.0956 3.75564 14.1557C2.96238 12.9661 2.5 11.5371 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10C17.5 11.5371 17.0376 12.9662 16.2443 14.1557ZM15.454 15.1482C14.0867 16.5963 12.1489 17.5 10 17.5C7.85112 17.5 5.91332 16.5963 4.54593 15.1482C6.22562 14.247 8.06603 13.75 9.99997 13.75C11.9339 13.75 13.7743 14.247 15.454 15.1482Z" fill="white" fillOpacity="0.9" />
                            </g>
                        </svg>
                    </div>
                    <div className={classes.name}>
                        {userInfo && userInfo.firstName}
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.54028 6.45964L4.45952 5.54041L7.9999 9.08079L11.5403 5.54041L12.4595 6.45964L7.9999 10.9193L3.54028 6.45964Z" fill="white" fillOpacity="0.9" />
                        </svg>
                    </div>
                </div>
            </Dropdown>
        </div>
    )
}

export default HomeUser;