import React, { useEffect } from 'react';
import classes from './login.module.css';
import { Form, message } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { EyeOutlined } from '@ant-design/icons';

import { useLogin } from './useLogin';
import TextInput from '../../components/formDesign/TextInput/textInput';
import LandingWelcome from '../../components/LandingWelcome/landingWelcome';

import ButtonNode from '../../components/formDesign/ButtonNode/buttonNode';
import CheckboxNode from '../../components/formDesign/CheckboxNode/checkboxNode';

import {
    IconUserGreySvg,
    IconPasswordLockGreySvg,
    IconPasswordHideGreySvg,
    IconPasswordVisibleGreySvg
} from '../../components/IconsSvg/iconsSvg';

const Login = props => {

    const { formatMessage } = useIntl();
    const [messageApi, contextHolder] = message.useMessage();

    const talons = useLogin();

    const {
        form,
        clientReady,
        loginLoading,
        onStartLogin,
        errorMsg,
        showErrorMsg,
        setShowErrorMsg,
        handleForgetPassWord
    } = talons;

    useEffect(() => {
        if (showErrorMsg) {
            messageApi.open({
                type: 'error',
                content: errorMsg,
            });
            setShowErrorMsg(false);
        }

    }, [showErrorMsg, errorMsg, messageApi, setShowErrorMsg]);

    return (
        <div className={classes.root}>
            {contextHolder}
            <aside className={classes.panel}>
                <div className={classes.panel_left}>
                    <LandingWelcome />
                </div>
                <div className={classes.panel_right}>
                    <div className={classes.right_box}>
                        <div className={classes.right_content}>
                            <h1>
                                <FormattedMessage id="login.panelName" defaultMessage={"Sign-in system"} />
                            </h1>
                            <Form className={classes.form} form={form} name="login_form" onFinish={onStartLogin} layout='vertical'>
                                <TextInput
                                    name="email"
                                    rules={[{
                                        required: true,
                                        message: formatMessage({
                                            id: "form.isRequired",
                                            defaultMessage: "{name} is required!"
                                        }, {
                                            name: formatMessage({
                                                id: "form.email",
                                                defaultMessage: "Email"
                                            })
                                        })
                                    }]}
                                    placeholder={formatMessage({
                                        id: "form.placeholder",
                                        defaultMessage: "Please enter {name} "
                                    }, {
                                        name: formatMessage({
                                            id: "form.emailPlaceholder",
                                            defaultMessage: "Email"
                                        })
                                    })}
                                    styles={{
                                        flexDirection: 'column'
                                    }}
                                    prefix={<IconUserGreySvg />}
                                />
                                <TextInput
                                    name="password"
                                    rules={[{
                                        required: true,
                                        message: formatMessage({
                                            id: "form.isRequired",
                                            defaultMessage: "{name} is required!"
                                        }, {
                                            name: formatMessage({
                                                id: "form.password",
                                                defaultMessage: "Password"
                                            })
                                        })
                                    }]}
                                    inputType="password"
                                    prefix={<IconPasswordLockGreySvg />}
                                    placeholder={formatMessage({
                                        id: "form.placeholder",
                                        defaultMessage: "Please enter {name} "
                                    }, {
                                        name: formatMessage({
                                            id: "form.passwordPlaceholder",
                                            defaultMessage: "Password"
                                        })
                                    })}
                                    iconRender={(visible) => (visible ? IconPasswordVisibleGreySvg
                                        : IconPasswordHideGreySvg)}
                                />
                                <div className={classes.form_other}>
                                    <CheckboxNode
                                        name="remember"
                                        valuePropName="checked"
                                    >
                                        <FormattedMessage id="login.rememberMeLabel" defaultMessage={"Remember me"} />
                                    </CheckboxNode>
                                    <ButtonNode
                                        type="link"
                                        onClick={handleForgetPassWord}
                                    >
                                        <FormattedMessage id="login.forgetBtn" defaultMessage={"Forget the password ?"} />
                                    </ButtonNode>
                                </div>
                                <Form.Item shouldUpdate>
                                    {() => (
                                        <ButtonNode
                                            type="primary"
                                            htmlType="submit"
                                            disabled={
                                                !clientReady ||
                                                !!form.getFieldsError().filter(({ errors }) => errors.length).length
                                            }
                                            loading={loginLoading}
                                            style={{ width: '100%' }}
                                        >
                                            <FormattedMessage id="login.loginBtn" defaultMessage={"Sign In"} />
                                        </ButtonNode>
                                    )}
                                </Form.Item>
                            </Form>
                        </div>
                        {/* <div className={classes.right_bottom}>
                            <FormattedMessage id="global.copyright " defaultMessage={"Copyright @ 2024 BCNetcom. All Rights Reserved"} />
                        </div> */}
                    </div>
                </div>
            </aside>
        </div>
    )
}

export default Login;