import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Outlet, matchRoutes, useLocation, useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Layout, Menu } from 'antd';
import classes from './homePage.module.css';
// import { routes } from '../../router';

import SwitchLanguage from '../../components/SwitchLanguage/switchLanguage'
import HomeUser from '../../components/HomeUser/homeUser';

import {
    IconDashboardGraySvg,
    IconDashboardBlueSvg,
    IconMenuUserGreySvg,
    IconMenuUserBlueSvg,
    IconMerchantGreySvg,
    IconMerchantBlueSvg,
    IconMerchantServiceGreySvg,
    IconMerchantServiceBlueSvg,
    IconServiceAliasGreySvg,
    IconServiceAliasBlueSvg,
    IconServicePlanGreySvg,
    IconServicePlanBlueSvg,
    IconMerchantServiceInstanceGreySvg,
    IconMerchantServiceInstanceBlueSvg,
    IconPermissionGreySvg,
    IconPermissionBlueSvg

} from '../../components/IconsSvg/iconsSvg';

const { Header, Sider, Content } = Layout;

const Homepage = props => {

    const { formatMessage } = useIntl();
    const location = useLocation();
    const navigate = useNavigate();
    const [defaultSelectedKeys, setDefaultSelectedKeys] = useState([location.pathname]);
    const [defaultOpenKeys, setDefaultOpenKeys] = useState([]);
    const [nowKey, setNowKey] = useState(location.pathname);

    const navItems = [
        {
            key: '/dashboard',
            icon: <IconDashboardGraySvg />,
            active_icon: <IconDashboardBlueSvg />,
            label: formatMessage({
                id: "homeMenu.dashboard",
                defaultMessage: "Dashboard"
            }),
            path: '/dashboard'
        },
        {
            key: '/admin-role',
            icon: <IconPermissionGreySvg />,
            active_icon: <IconPermissionBlueSvg />,
            label: formatMessage({
                id: "homeMenu.adminRole",
                defaultMessage: "Admin Role"
            }),
            path: '/admin-role'
        },
        {
            key: '/users',
            icon: <IconMenuUserGreySvg />,
            active_icon: <IconMenuUserBlueSvg />,
            label: formatMessage({
                id: "homeMenu.users",
                defaultMessage: "Users"
            }),
            path: '/users'
        },
        {
            key: '/merchants',
            icon: <IconMerchantGreySvg />,
            active_icon: <IconMerchantBlueSvg />,
            label: formatMessage({
                id: "homeMenu.merchants",
                defaultMessage: "Merchants"
            }),
            path: '/merchants'
        },
        // {
        //     key: '/merchants-vendor',
        //     icon: <IconMerchantGreySvg />,
        //     active_icon: <IconMerchantBlueSvg />,
        //     label: formatMessage({
        //         id: "homeMenu.merchantsVendor",
        //         defaultMessage: "Merchants Vendor"
        //     }),
        //     path: '/merchants-vendor'
        // },
        {
            key: '/service-alias',
            icon: <IconServiceAliasGreySvg />,
            active_icon: <IconServiceAliasBlueSvg />,
            label: formatMessage({
                id: "homeMenu.serviceAlias",
                defaultMessage: "Service Alias"
            }),
            path: '/service-alias'
        },
        {
            key: '/service-plan',
            icon: <IconServicePlanGreySvg />,
            active_icon: <IconServicePlanBlueSvg />,
            label: formatMessage({
                id: "homeMenu.servicePlan",
                defaultMessage: "Service Plan"
            }),
            path: '/service-plan'
        },
        {
            key: '/system-operation-log',
            icon: <IconServicePlanGreySvg />,
            active_icon: <IconServicePlanBlueSvg />,
            label: formatMessage({
                id: "homeMenu.operationLog",
                defaultMessage: "Operation Log"
            }),
            path: '/system-operation-log'
        }


    ];

    const MenuData = useMemo(() => {
        let data = [];
        function getChildMenu(childMenu) {
            if (!childMenu) return null;

            let data2 = [];
            childMenu.forEach(item => {
                data2.push({
                    key: item.key,
                    icon: null,
                    children: null,
                    label: item.label,
                })
            })
            return data2
        }
        navItems.forEach(item => {
            data.push({
                key: item.key,
                icon: nowKey === item.key ? item.active_icon : item.icon,
                children: getChildMenu(item.children),
                label: item.label,
            })
        })
        return data
    }, [nowKey])

    const handleMenuSelected = useCallback((val) => {
        if (val && val.key) {
            setNowKey(val.key);
        }
    }, []);

    const handleClickMenu = useCallback((e) => {
        if (e.key.includes('childrenMenu')) {
            return false;
        }
        else {
            navigate(e.key);
        }
    }, [navigate]);

    const menuNode = useMemo(() => {
        return <Menu
            // 根据url地址实现选中高亮
            mode='inline'
            selectedKeys={defaultSelectedKeys}
            openKeys={defaultOpenKeys}
            items={MenuData}
            onSelect={(val) => { handleMenuSelected(val) }}
            onClick={handleClickMenu}
            inlineIndent={12}
        >
            {/* {
                navItems.map(menu=>{
                    return <Menu.Item key={menu.key}>
                        {nowKey === menu.key ? menu.active_icon : menu.icon}
                        <Link to={menu.path}>{menu.label}</Link>
                    </Menu.Item>
                })
            } */}
        </Menu>
    }, [defaultOpenKeys, defaultSelectedKeys, MenuData, handleClickMenu, handleMenuSelected])

    useEffect(() => {

        setDefaultSelectedKeys([location.pathname]);
        setDefaultOpenKeys([location.pathname]);
        setNowKey(location.pathname)
    }, [location.pathname]);

    return (
        <Layout className={classes.root}>
            <Header style={{ padding: '0 24px' }}>
                <div className={classes.header_title}>
                    <FormattedMessage id="global.webName" defaultMessage={"E-Com365  Go CRM Micro-service"} />
                </div>
                <div className={classes.header_right}>
                    <SwitchLanguage />
                    <div className={classes.headerRightBox}>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.87378 4.99866C1.87378 4.3083 2.43342 3.74866 3.12378 3.74866H16.8738C17.5641 3.74866 18.1238 4.3083 18.1238 4.99866V14.9987C18.1238 15.689 17.5641 16.2487 16.8738 16.2487H3.12378C2.43342 16.2487 1.87378 15.689 1.87378 14.9987V4.99866ZM16.372 4.99866H3.62551L9.99878 9.2475L16.372 4.99866ZM3.12378 6.16648L3.12378 14.9987H16.8738V6.16648L9.99878 10.7498L3.12378 6.16648Z" fill="white" fillOpacity="0.9" />
                        </svg>
                        <div className={classes.messageNumBox}>
                            2
                        </div>
                    </div>
                    <div className={classes.headerRightBox}>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.24255 14.5C9.24255 14.0858 9.57834 13.75 9.99255 13.75C10.4068 13.75 10.7426 14.0858 10.7426 14.5C10.7426 14.9142 10.4068 15.25 9.99255 15.25C9.57834 15.25 9.24255 14.9142 9.24255 14.5Z" fill="white" fillOpacity="0.9" />
                            <path d="M9.99261 5.00763C8.32056 5.00763 6.96136 6.36682 6.96136 8.03888H8.21136C8.21136 7.05718 9.01092 6.25763 9.99261 6.25763C10.9743 6.25763 11.7739 7.05718 11.7739 8.03888C11.7739 8.71106 11.2183 9.3851 10.4931 9.67971L10.4899 9.68101C9.82793 9.95419 9.36767 10.6052 9.36767 11.3656V12.5H10.6177V11.3656C10.6177 11.1228 10.7618 10.9216 10.9655 10.837C11.9996 10.4161 13.0239 9.37337 13.0239 8.03888C13.0239 6.36682 11.6647 5.00763 9.99261 5.00763Z" fill="white" fillOpacity="0.9" />
                            <path d="M18.7427 10C18.7427 5.16751 14.8252 1.25 9.99268 1.25C5.16019 1.25 1.24268 5.16751 1.24268 10C1.24267 14.8325 5.16018 18.75 9.99267 18.75C14.8252 18.75 18.7427 14.8325 18.7427 10ZM17.4927 10C17.4927 14.1421 14.1348 17.5 9.99267 17.5C5.85054 17.5 2.49267 14.1421 2.49268 10C2.49268 5.85786 5.85054 2.5 9.99268 2.5C14.1348 2.5 17.4927 5.85787 17.4927 10Z" fill="white" fillOpacity="0.9" />
                        </svg>
                    </div>
                    <HomeUser />
                    <div className={classes.headerRightBox}>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.7501 10C13.7501 12.0711 12.0712 13.75 10.0001 13.75C7.92902 13.75 6.25009 12.0711 6.25009 10C6.25009 7.92893 7.92902 6.25 10.0001 6.25C12.0712 6.25 13.7501 7.92893 13.7501 10ZM12.5001 10C12.5001 8.61929 11.3808 7.5 10.0001 7.5C8.61937 7.5 7.50009 8.61929 7.50009 10C7.50009 11.3807 8.61937 12.5 10.0001 12.5C11.3808 12.5 12.5001 11.3807 12.5001 10Z" fill="white" fillOpacity="0.9" />
                            <path d="M10.0001 1.5625L17.5778 5.78125V14.2188L10.0001 18.4375L2.42236 14.2188V5.78125L10.0001 1.5625ZM3.67236 6.516V13.484L10.0001 17.0068L16.3278 13.484V6.516L10.0001 2.99316L3.67236 6.516Z" fill="white" fillOpacity="0.9" />
                        </svg>
                    </div>
                </div>
            </Header>
            <Layout>
                <Sider>
                    {menuNode}
                </Sider>
                <Content>
                    <Outlet />
                    <div className={classes.footer}>
                        Copyright @ 2024 BCNetcom. All Rights Reserved
                    </div>
                </Content>
            </Layout>
        </Layout>
    )
}

export default Homepage;